import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface CenterCoordinates {
    latitude: number;
    longitude: number;
}

interface RecenterMapProps {
    center: CenterCoordinates;
}

const RecenterMap: React.FC<RecenterMapProps> = ({ center }) => {
    const map = useMap();

    useEffect(() => {
        if (center) {
            map.setView([center.latitude, center.longitude], map.getZoom());
        }
    }, [center, map]);

    return null;
};

export default RecenterMap;

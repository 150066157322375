import './SearchPage.css';
import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

interface SelectOption {
  value: string;
  label: string;
}

interface Coordinates {
  latitude: string;
  longitude: string;
}

const SearchPage = () => {

  // State for search and filters

  // For buildings search box
  const [inputValue, setInputValue] = useState<string>('');
  // For buildings selection
  const [selectedOption, setSelectedOption] = useState<SingleValue<SelectOption>>(null);
  // For lot selection (all, visitor)
  const [selectedRadio, setSelectedRadio] = useState<string>("all");
  const [isVisitor, setIsVisitor] = useState<boolean>(false);
  // For kiosk, park-by-plate lots
  const [selectedOptions, setSelectedOptions] = useState<string[]>(["kiosk", "mobile"]);

  const [buildings, setBuildings] = useState<SelectOption[]>([]);  // Initialize state
  const ip_address = process.env.REACT_APP_IP_ADDRESS; 
  const zot_park_api_key = process.env.REACT_APP_ZOT_PARK_API_KEY;
  useEffect(() => {
    // Call the backend endpoint to get buildings
    fetch(`http://${ip_address}:8080/zotParking/find/buildings/all`, {
      method: 'GET',
      headers: {
        'X-API-KEY': `${zot_park_api_key}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        // Map the fetched data to match the SelectOption format
        const options: SelectOption[] = data.map((building: any) => ({
          value: building.abbreviation,  
          label: `${building.buildingName} (${building.abbreviation})`   
        }));

        // Update the buildings state
        setBuildings(options);
      })
      .catch((error) => console.error('Error fetching buildings:', error));
  }, [ip_address]);

  const handleInputChange = (input: string) => {
    setInputValue(input);
  };

  const handleChange = (option: SingleValue<SelectOption>) => {
    setSelectedOption(option);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
    if (event.target.value !== 'visitor') {
      setIsVisitor(false);
    }
    else {
      setIsVisitor(true);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

        // Toggle the checkbox selection
        setSelectedOptions(prevOptions => 
            prevOptions.includes(value) 
                ? prevOptions.filter(option => option !== value) // Remove if already selected
                : [...prevOptions, value] // Add if not selected
        );
  };

  // Filtered buildings based on search query
  const filteredBuildings = buildings.filter((building) => {
    return (
      building.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  });

  const navigate = useNavigate();

  const handleSearch = () => {

    /* DEBUGGING */
    // console.log('Searching for:', selectedOption);

    // call API to get nearest Lot and coordinates

    if (selectedOption) {
      fetch(`http://${ip_address}:8080/zotParking/find/${selectedOption.value}`, {
        method: 'GET',
        headers: {
          'X-API-KEY': `${zot_park_api_key}`
        }
      })
      .then((response) => response.json())
      .then((data) => {
        var lot_result: String = "";
        var lot_coordinates: Coordinates;
        if (selectedRadio === 'all') {
          /* DEBUGGING */
          // console.log(data['closest_lot']);
          lot_result = data['closest_lot'];
          lot_coordinates = data['closest_lot_coordinates'];
        }
        else {
          // For visitor lots, check which options are selected
          if (selectedOptions.includes('kiosk') && !selectedOptions.includes('mobile')) {
            /* DEBUGGING */
            // console.log(data['closest_dispenser_lot']);
            lot_result = data['closest_dispenser_lot'];
            lot_coordinates = data['closest_dispenser_lot_coordinates'];
          } 
          else if (selectedOptions.includes('mobile') && !selectedOptions.includes('kiosk')) {
            /* DEBUGGING */
            // console.log(data['closest_mobile_pay_lot']);
            lot_result = data['closest_mobile_pay_lot'];
            lot_coordinates = data['closest_mobile_pay_lot_coordinates'];
          }
          else {
            // If both or neither 'kiosk' and 'mobile' are selected
            /* DEBUGGING */
            // console.log(data['closest_payable_lot']);
            lot_result = data['closest_payable_lot'];
            lot_coordinates = data['closest_payable_lot_coordinates'];
          }
        }
        // navigate to map page and pass info
        navigate('/', { state: {
          building_id: data.buildingId,
          building_name: data.buildingName,
          building_coords: data.building_coordinates,
          closest_lot: lot_result,
          lot_coords: lot_coordinates,
        } });
        /* DEBUGGING */
        console.log('Building details', data);
      })
      .catch((error) => console.error('Error fetching building details:', error));
    }
    
  }


  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (

    <div className="search-page">

      <h1 className="title">ZOT PARK</h1>

      {/* Back Button */}
      <div className="back-button" onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} size="lg" /> Back
      </div>

      <div className="container search-box">

        <div className="radio-buttons">
          <h2>Select Type of Lot:</h2>
          <label className="custom-radio">
            <input
                type="radio"
                value="all"
                checked={selectedRadio === 'all'}
                onChange={handleRadioChange}
            />
            All Lots
          </label>
          <label className="custom-radio">
            <input
                type="radio"
                value="visitor"
                checked={selectedRadio === 'visitor'}
                onChange={handleRadioChange}
            />
            Visitor Lots
          </label>
        </div>

        <div className="checkboxes">
          <label className="custom-checkbox">
            <input
                type="checkbox"
                value="kiosk"
                checked={selectedOptions.includes('kiosk')}
                onChange={handleCheckboxChange}
                disabled={!isVisitor}
            />
            Lots with Permit Kiosk
          </label>
          <br />
          <label className="custom-checkbox">
            <input
                type="checkbox"
                value="mobile"
                checked={selectedOptions.includes('mobile')}
                onChange={handleCheckboxChange}
                disabled={!isVisitor}
            />
            Lots with Mobile Park-By-Plate
          </label>
          <br />
        </div>

        <div className="search-bar">
          <Select className="search-input"
            value={selectedOption}
            onChange={handleChange}
            onInputChange={handleInputChange}
            options={filteredBuildings}
            placeholder="Search buildings..."
            isClearable
            isSearchable
          />

          <button onClick={handleSearch}>Search</button>
        </div>

      </div>

    </div>

  );

};


export default SearchPage;
